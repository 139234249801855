.dried-tile {
    width: 15vw;
    height: fit-content;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    border-radius: 15px;
    background-color: var(--color-2-lighter);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 3px;
    z-index: 2;
    color: var(--color-0);
    text-decoration: none;
}

.dried-tile-img-buttons {
    position: absolute;
    width: 15vw;
    height: 15vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

#dried-tile-img-buttons-cart {
    display: none;
    cursor: pointer;
    width: 100%;
    padding: 5px 0;
    background-color: rgba(250, 235, 215, 0.849);
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.dried-tile:hover #dried-tile-img-buttons-cart {
    display: flex;
}

#dried-tile-img-buttons-fav {
    padding: 10px;
    width: 2vw;
    cursor: pointer;
    border-image: white 10px solid;
}

.dried-tile-img-button {
    z-index: 35;
}

.dried-tile-img {
    position: relative;
    width: 15vw;
    min-width: 15vw;
    height: 15vw;
    min-height: 15vw;
    object-fit: cover;
    z-index: 30;
}

.dried-tile-name {
    text-align: center;
    font-size: 27px;
    font-weight: 600;
    margin-top: 0.5vw;
    font-family: var(--font-cursive);
}

.dried-tile-price {
    font-weight: bold;
    margin: none;
    text-align: center;
    padding-bottom: 5px;
}

.admin-fresh-name {
    cursor: default;
}

@media (width <=768px) {
    .dried-tile {
        width: 50vw;
    }

    .dried-tile-img-buttons {
        width: 50vw;
        height: 50vw;
    }

    #dried-tile-img-buttons-fav {
        width: 5vw;
    }

    #dried-tile-img-buttons-cart {
        width: 100%;
        font-size: 11px;
    }

    .dried-tile-img {
        width: 50vw;
        min-width: 50vw;
        height: 50vw;
        min-height: 50vw;
    }

    .dried-tile-name {
        font-size: 6vw;
    }

    .dried-tile-price {
        font-size: 4vw;
    }
}