.admin-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    width: 60%;
    padding: 3vw;
    margin: 20px;
    border-radius: 2vw;
    border: var(--color-3) 2px solid;
    box-shadow: 2px 2px 4px rgb(141, 141, 141);
}

.admin-form-sub-title {
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
    padding: 20px 0 0;
    width: 100%;
    border-top: 1px solid var(--color-3-darker);
}

.admin-form-infotext {
    margin-top: 0;
}

.admin-form-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    width: 100%;
}

.admin-form-label {
    display: flex;
}

.admin-form-element-right {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-form-input {
    width: calc(60% - 22px);
    font-size: 14px;
    height: fit-content;
    border-radius: 4px;
    border: var(--color-2-darker) 1px solid;
    padding: 10px;
}

.admin-form-description {
    white-space: pre-wrap;
}

.admin-form-input-right {
    width: calc(100% - 22px);
}

#admin-form-element-alreadytaken {
    color: var(--color-3);
    font-size: 13px;
    font-weight: bold;
    margin: 0;
}

.admin-form-input-select {
    min-width: 60%;
    max-width: 60%;
    min-height: 30px;
    height: fit-content;
    border-radius: 5px;
    cursor: pointer;
}

#admin-form-input-description {
    min-height: 50px;
}

#admin-form-color-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

.admin-form-color {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid black;
}

#admin-form-input-files:hover {
    cursor: pointer;
}

.admin-form-input-info {
    font-size: 12px;
    margin: 0;
    padding: 0;
}

#admin-form-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    width: 100%;
    margin: 10px 0;
}

#admin-form-image-first {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-form-image {
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

.admin-fresh-catalog {
    margin-top: 20px;
}

#admin-article-delete-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

#admin-article-delete-popup {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 203, 203, 0.938);
    border: red 1px solid;
    width: 40vw;
    padding: 10px;
    border-radius: 10px;
    z-index: 10;
    text-align: center;
}

.admin-form-image-delete {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.45);
    border-radius: 5px;
    z-index: 5;
    width: 100px;
    height: 100px;
}

.admin-form-image-tile:hover .admin-form-image-delete {
    display: flex;
}

.admin-form-image-delete-text {
    border-radius: 5px;
    text-align: center;
    color: black;
    font-weight: bold;
    font-style: italic;
    margin: 0px;
}

#admin-form-input-new-color {
    min-width: 60%;
    max-width: 60%;
    width: 50px;
    height: 35px;
    border: none;
    background-color: transparent;
    padding: 0px;
}

.admin-fresh-tile-button {
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: small;
    text-align: center;
    padding: 10px 0px;
    font-weight: bold;
    font-style: italic;
}

@media (max-width: 768px) {
    .admin-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        width: 85vw;
    }
}