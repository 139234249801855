#contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5vw 20vw;
}

#contact-emma {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60vw;
    margin: 50px 0;
    gap: 50px;
    text-align: left;
}

#contact-emma-img {
    width: 20vw;
    border-radius: 2vw;
}

.contact-emma-text {
    font-size: large;
}


.contact-emma-marche {
    font-size: large;
    text-align: left;
}

.contact-info-separation {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: transparent;
    cursor: default;
    font-size: 0px;
}

.contact-url {
    color: #000;
    text-decoration: none;
    font-weight: bold;
}

@media (width <=768px) {
    #contact-emma {
        flex-direction: column;
        margin: 0px;
        gap: 20px;
    }

    #contact-emma-img {
        width: 40vw;
    }

    .contact-emma-text {
        font-size: medium;
    }

    .contact-emma-marche {
        font-size: medium;
    }
}