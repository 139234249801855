.alert {
    display: none;
    z-index: 98;
}

#alert-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

#alert-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 99;
    top: 50%;
    left: calc(50% - 150px);
    width: 300px;
    text-align: center;

    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
    padding: 20px;
    border-radius: 10px;
}

#alert-button {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    background-color: white;
    border: none;
    cursor: pointer;
}