.page {
    margin: 5vh;
}

.link {
    text-decoration: none;
    color: inherit;
}

.paragraph {
    font-size: 18px;
}

.paragraph-center {
    text-align: center;
}

.page-title {
    font-size: 4vw;
    font-weight: normal;
    font-family: 'Great Vibes', cursive;
    text-align: center;
}

/* Mandatory Form Elements */
#form-mandatory-info {
    display: flex;
    align-items: center;
}

.form-mandatory {
    color: var(--color-3);
    font-weight: bold;
    margin: 0 2px;
    font-size: small;
}

#form-mandatory-text {
    margin: 0;
    font-size: small;
}

@media screen and (max-width: 768px) {
    .page-title {
        font-size: 10vw;
    }
}