.admin-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-page-title {
    width: fit-content;
    text-align: center;
}

.admin-element-title {
    margin: 0px;
    text-align: center;
}

.admin-button {
    border: var(--color-logo-dark) 1px solid;
    border-radius: 5px;
    background-color: var(--color-logo-light);
    cursor: pointer;
    text-decoration: none;
    color: black;
    text-align: center;

    padding: 10px 20px;
    margin: 5px;
}

.admin-button:disabled {
    border: #979797 1px solid;
    border-radius: 5px;
    background-color: #c2c2c2;
    cursor: not-allowed;
    text-decoration: none;
    color: #ececec;
    text-align: center;

    padding: 10px 20px;
    margin: 5px;
}

.admin-delete-button {
    border: #ff0000 1px solid;
    border-radius: 5px;
    background-color: #ffb4b4;
    cursor: pointer;

    padding: 10px 20px;
    margin: 5px;
    height: 40px;
    font-weight: bold;
}

.admin-button:active {
    background-color: var(--color-logo-dark);
}