#admin-events-select-area {
    display: flex;
    align-items: center;
    gap: 10px;
}

#admin-events-select {
    border-radius: 5px;
    padding: 5px;
    height: fit-content;
}

#admin-events-catalogs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 60%;
    cursor: pointer;
}