#profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
    margin: 5vh 0;
}

.profile-container {
    background-color: var(--color-2-lighter);
    padding: 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

#profile-container-infos {
    display: flex;
    gap: 20px;
    padding: 20px;
}

.profile-container-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile-button {
    margin: 10px;
    padding: 10px;
    border: var(--color-2-darker) 1px solid;
    background-color: var(--color-2-lighter);
    border-radius: 5px;
    cursor: pointer;
}