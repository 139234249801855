#header {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#header-top-buttons {
    display: flex;
    justify-content: space-between;
}

.header-top-button-withicon {
    display: flex;
    gap: 5px;
}

.header-top-button-icon {
    height: 20px;
    width: 20px;
    padding-top: 2px;
}

#header-button-cart {
    padding-top: 0px;
}

#header-button-profile {
    padding-top: 0px;
}

#header-top-buttons-right {
    display: flex;
    gap: 10px;
    margin-right: 10px;
    height: 0px;
}

.header-top-button {
    margin: 5px;
    cursor: pointer;
}

.header-top-button-with-border {
    margin: 5px;
    padding: 5px 10px;
    height: fit-content;
    border: var(--color-2-darker) 1px solid;
    border-radius: 5px;
    cursor: pointer;
}

#header-top-button-burger-area {
    height: 30px;
}

#header-top-button-burger {
    display: none;
    cursor: pointer;
    position: fixed;
    top: 0px;
    z-index: 80;
    background-color: var(--color-1);
    padding: 5px;
    border-radius: 5px;
}

#header-logo-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#header-logo {
    height: 20vh;
    width: 20vh;
    cursor: pointer;
    padding: 0px;
    margin-top: 0px;
}

#header-name {
    margin: 0px;
    margin-bottom: 10px;
    font-size: 50px;
    font-family: 'Great Vibes', cursive;
    text-align: center;
    font-weight: normal;
}

#header-buttons-area {
    position: sticky;
    top: 0px;
    z-index: 70;
}

/* Buttons Big Screen */
#header-buttons {
    background-color: var(--color-2);
    color: var(--color-1);
    min-height: 5vh;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 6px;
}

.header-item {
    cursor: pointer;
    text-decoration-color: initial;
    padding: 10px 5px;
}

.header-dropdowm {
    display: none;
    position: absolute;
    background-color: var(--color-2);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    min-width: 100px;
    overflow: hidden;

    margin-top: 10px;
    margin-left: -10px;
    cursor: pointer;
}

/* Header Buttons Small Screen */
#header-buttons-mobile {
    display: none;
}

/*Catalog*/
#catag:hover #catag-dropdown {
    display: flex;
    flex-direction: column;
}

#inspi:hover #inspi-dropdown {
    display: flex;
    flex-direction: column;
}

.header-dropdown-item {
    margin: 0px;
    padding: 5px 10px;
    padding-right: 30px;
    text-decoration-color: initial;
}

.header-dropdown:first-child {
    padding-top: 10px;
}

.header-dropdown-item:hover {
    background-color: var(--color-3);
}

#header-item-event {
    display: flex;
    justify-content: flex-end;
}

#header-item-event-button {
    margin: 15px 0px;
}

.header-item-bold {
    background-color: var(--color-3);
    width: fit-content;
    border-radius: 3px;
    padding: 2px;

    position: absolute;
    margin-top: 10px;
    margin-right: -30px;
    justify-self: flex-end;

    font-weight: bold;
    font-size: x-small;
}

@media (width <=768px) {
    #header-buttons-area {
        position: initial;
        top: initial;
    }

    #header-buttons-pc {
        display: none;
    }

    #header-buttons-mobile {
        display: none;
        align-items: flex-start;
        width: 100vw;
        min-height: 100vh;
        height: fit-content;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 80;
        background-color: rgba(0, 0, 0, 0.432);
    }

    /*Client Buttons*/
    #header-buttons-mobile-area {
        padding-top: 50px;
        min-height: 100vh;
        height: fit-content;
        width: 80vw;

        background-color: var(--color-2);
        color: var(--color-1);
        font-size: large;
    }

    .header-lines {
        border: 0px;
        border-top: var(--color-2-darker) 1px solid;
        margin-left: 0%;
    }

    #header-top-button-burger {
        display: initial;
        width: 20px;
    }

    #header-item-event-small {
        display: flex;
        align-items: center;
    }

    .header-item-bold {
        position: relative;
        height: fit-content;
        margin: 0px;
    }

    .header-item-small {
        margin: 0;
        cursor: pointer;
        padding: 10px;
    }

    .header-item-small:hover {
        background-color: var(--color-3);
    }

    .header-dropdown-item {
        margin-left: 15px;
    }

    #header-line-client-admin-separator {
        border: 0px;
        border-top: var(--color-2-darker) 1px solid;
        margin: 0;
    }

    /*Non Header Part*/
    #header-hide-page {
        width: 20vw;
        min-height: 100vh;
    }
}