#admin-week-gradient {
    width: 70%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid rgb(184, 184, 184);
    margin-bottom: 30px;
}

#admin-week-colors-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    min-height: 60px;
    padding: 15px 0px;
}

.admin-week-colors-color {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.admin-week-colors-display {
    width: 50px;
    height: 35px;
    border: none;
    background-color: transparent;
    padding: 0px;
    border-radius: 3px;
}

.admin-week-colors-delete {
    background-color: white;
    border: 2px solid red;
    border-radius: 5px;
    padding: 2px 7px;
}