#footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    margin-top: auto;
    margin-bottom: 0;
    padding: 10px 0;

    height: fit-content;
    background-color: var(--color-2);
    color: var(--color-1);
    font-size: smaller;
}

.footer-col {
    width: fit-content;
    height: fit-content;
}

.footer-url {
    margin: 5px;
    cursor: pointer;
    width: fit-content;
    font-weight: bold;
    color: var(--color-1);
    text-decoration: none;
}

@media (width <=768px) {
    #footer {
        padding: 5px 0;
        font-size: x-small;
    }
}