.catalog {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vw;
}

#week-gradient {
    width: 70%;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 30px;
    margin-top: -3vw;
}

#dried-flowers-catalog {
    display: flex;
    flex-direction: column;
    gap: 6vw;
}

.dried-flowers-row {
    display: flex;
    gap: 6vw;
}

.horizontal-bar {
    width: 50%;
    height: 2px;
    background-color: var(--color-2);
    border: none;
    margin-top: 3vw;
}

@media (width <=768px) {
    .dried-flowers-row {
        flex-direction: column;
    }
}