#inspiration {
    margin: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5vw;
}

.inspiration-image {
    width: 15vw;
    height: 15vw;
    border-radius: 10px;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

/* Menu */
#inspirations-menu {
    display: flex;
    gap: 2vw;
}

.inspiration-tile {
    width: 25vw;
    height: 25vw;
    border-radius: 10px;
    display: flex;
    position: relative;
    z-index: 1;
}

.inspirations-tileback {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 25vw;
    height: 25vw;
    background-color: rgba(255, 255, 255, 0.45);
    border-radius: 10px;
    z-index: 5;
}

.inspiration-tileimage {
    width: 25vw;
    height: 25vw;
    border-radius: 10px;
    object-fit: cover;
}

.inspirations-tilename {
    font-size: 3.5vw;
    font-family: 'Great Vibes', cursive;
    margin-bottom: 0px;
    text-align: center;
    color: black;
}

/* Pages */
.inspirations-subtitle {
    font-size: larger;
    font-weight: normal;
    margin-top: 0px;
    text-align: center;
}

.inspirations-text {
    width: 60vw;
    text-align: center;
    font-size: 18px;
}

/* ================== *
**   Vertical Stack   *
** ================== */
.inspirations-vertical-stack-area {
    display: flex;
    gap: 2vw;
}

.inspirations-vertical-stack {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.inspirations-vertical-stack-main {
    width: 32vw;
    height: 32vw;
}

/* ===================== *
**   Vertical Tripanel   *
** ===================== */
.inspirations-vertical-tripanel {
    display: flex;
    gap: 2vw;
}

.inspirations-vertical-tripanel-stack {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.inspiration-vertical-tripanel-tall {
    width: 15vw;
    height: 32vw;
}


/* ===================== *
**   Vertical bipanel   *
** ===================== */
.inspirations-vertical-bipanel {
    display: flex;
    gap: 2vw;
}

.inspiration-vertical-bipanel-tall {
    width: 15vw;
    height: 25vw;
}

/* ===================== *
**   Horizontal   *
** ===================== */
.inspirations-horizontal-two-images-area {
    display: flex;
    gap: 2vw;
}

.inspirations-horizontal-two-images {
    width: 23.5vw;
    height: 23.5vw;
}

.inspirations-horizontal-area {
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.inspirations-horizontal-line {
    display: flex;
    gap: 2vw;
}

.inspirations-horizontal {
    width: 49vw;
    height: 20vw;
}

@media (max-width: 768px) {
    /* Menu */
    #inspirations-menu {
        display: flex;
        flex-direction: column;
        gap: 3vw;
    }

    .inspirations-tile {
        width: 70vw;
        height: 70vw;
    }

    .inspirations-tileback {
        width: 70vw;
        height: 70vw;
    }

    .inspiration-tileimage {
        width: 70vw;
        height: 70vw;
    }

    .inspirations-tilename{
        font-size: 10vw;
    }

    /* Pages */
    #inspiration {
        margin: 50px;
    }

    .inspiration-image {
        width: 29vw;
        height: 29vw;
    }

    .inspirations-title {
        font-size: 8vw;
        margin-top: 0px;
    }

    .inspirations-subtitle {
        font-size: large;
    }

    .inspirations-text {
        width: 80vw;
    }

    /* ================== *
    **   Vertical Stack   *
    ** ================== */
    .inspirations-vertical-stack-main {
        width: 60vw;
        height: 60vw;
    }

    .inspiration-vertical-tripanel-tall {
        width: 30vw;
        height: 60vw;
    }

    .inspiration-vertical-bipanel-tall {
        width: 30vw;
        height: 50vw;
    }

    .inspirations-horizontal-two-images {
        width: 45vw;
        height: 45vw;
    }

    .inspirations-horizontal {
        width: 91vw;
        height: 40vw;
    }
}