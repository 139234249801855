#legal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vw 20vw;
}

.legal-emma-text {
    font-size: large;
    text-align: justify;
    width: 100%;
}

.legal-emma-text-short {
    font-size: large;
    width: 100%;
}

.legal-emma-text-long {
    word-break: break-all;
}

.legal-emma-info {
    text-align: left;
    width: 100%;
}

.legal-info-separation {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: transparent;
    cursor: default;
    font-size: 0px;
}

@media (width <=768px) {
    #legal-emma {
        flex-direction: column;
        margin: 0px;
        gap: 20px;
    }

    #legal-emma-img {
        width: 40vw;
    }

    .legal-emma-text {
        font-size: medium;
    }

    .legal-emma-text-short {
        font-size: medium;
    }
}