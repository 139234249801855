.admin-edit-elements-list {
    width: 100%;
}

.admin-edit-elements {
    display: flex;
    width: 100%;
    gap: 5%;
}

.admin-edit-elements-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5%;
}

/* Color */
.admin-edit-elements-left {
    width: 60%;
}

.admin-edit-elements-center {
    width: 70%;
}

.admin-edit-elements-right {
    width: 50%;
}

.admin-edit-elements-label {
    width: 40%;
}

.admin-edit-elements-input {
    width: 100%;
}

.admin-delete-popup {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 203, 203, 0.938);
    border: red 1px solid;
    width: 40vw;
    padding: 10px;
    border-radius: 10px;
    z-index: 10;
    text-align: center;
}


.admin-edit-element-color {
    width: 30%;
    height: 35px;
    border: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
}

/* Other Elements */
.admin-edit-elements-buttons {
    display: flex;
    justify-content: space-between;
    gap: 5%;
    width: 100%;
}

@media (width <=768px) {
    .admin-edit-elements-list {
        display: flex;
        flex-direction: column;
        gap: 5vw;
    }

    .admin-edit-elements {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .admin-edit-elements-line {
        width: 90%;
    }

    /* Color */
    .admin-edit-elements-left {
        flex-direction: column;
        justify-content: center;
    }

    .admin-edit-elements-right {
        justify-content: center;
    }

    .admin-edit-elements-label {
        width: fit-content;
        font-weight: bold;
    }

    /* Other Elements */
    .admin-edit-elements-buttons {
        justify-content: center;
    }
}