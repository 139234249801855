#homepage {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 5vw;

    padding: 5vw 20vw;
    background-color: var(--color-1);
    color: var(--color-0);
    text-align: center;
}

#home-top {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: fit-content;
    height: fit-content;
    gap: 0.6vw;
}

/* Carousel */
#home-carousel {
    width: 30vw;
    height: 30vw;
    border-radius: 15px;
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

#home-carousel-dir-buttons-area {
    position: absolute;
    width: 30vw;
    display: flex;
    justify-content: space-between;
    z-index: 3;
}

.home-carousel-dir-buttons {
    height: 30vw;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.home-carousel-dir-buttons-arrow {
    height: 30px;
    width: 30px;
}

#home-carousel-list {
    display: flex;
    transition: 0.8s ease-in-out
}

.home-carousel-img {
    width: 30vw;
    height: 30vw;
    object-fit: cover;
}

/* Top Buttons */
#home-top-buttons-area {
    height: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-top-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    height: 9.6vw;
    width: 20vw;

    cursor: pointer;
    background-color: var(--color-2);
    border-radius: 15px;

    font-size: larger;
    font-weight: bold;
    color: var(--color-1);
    text-decoration: none;
}

/* Texts and Images */
.home-text-and-img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40vw;
    gap: 2vw;
    text-align: left;
}

.home-img-tall {
    width: 15vw;
    border-radius: 15px;
}

@media (width <=768px) {
    #home-top {
        flex-direction: column;
        justify-content: center;
    }

    /* Carousel */
    #home-carousel {
        width: 60vw;
        height: 60vw;
    }

    #home-carousel-dir-buttons-area {
        width: 60vw;
    }

    .home-carousel-dir-buttons {
        height: 60vw;
        width: 50px;
    }

    .home-carousel-img {
        width: 60vw;
        height: 60vw;
    }

    /* Top Buttons */
    #home-top-buttons-area {
        height: 30vw;
        padding-top: 5vw;
    }

    .home-top-buttons {
        width: 60vw;
        font-size: large;
    }

    /* Texts and Images */
    .home-text-and-img {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 60vw;
        gap: 4vw;
        text-align: center;
    }

    .home-img-tall {
        width: 50vw;
    }
}