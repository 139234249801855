#item-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0px;
    gap: 50px;
}

#item-page-top {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

/* ========== */
/*   Images   */
/* ========== */
#item-page-images {
    display: flex;
    gap: 10px;
}

#item-page-images-col {
    display: flex;
    flex-direction: column;
}

#item-page-images-col-mobile {
    display: none;
}

.item-page-images-col {
    width: 6vw;
    height: 6vw;
    object-fit: cover;
    margin: 2px;
    border-radius: 10px;
}

/* ========= */
/*   Infos   */
/* ========= */
#item-page-infos {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: large;
}

#item-page-info-name {
    font-weight: 400;
    font-family: var(--font-cursive);
    text-align: center;
    font-size: 40px;
}

#item-page-info-price {
    font-weight: bold;
}

#item-page-info-favorite {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

#item-page-description {
    width: 70vw;
}

#item-page-carousel-list {
    display: flex;
    transition: 0.8s ease-in-out
}

/* Big Image */
#big-carousel-area {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-color: rgba(2, 2, 2, 0.541);
    z-index: 83;
    display: none;
    cursor: zoom-out;
}

#big-carousel {
    --big-carousel-size: min(80vw, 80vh);

    display: none;
    width: var(--big-carousel-size);
    height: var(--big-carousel-size);
    border-radius: 15px;
    overflow: hidden;
    position: absolute;
    z-index: 85;
    top: min(10vw, 10vh);
}

#item-page-carousel-display-big {
    height: 30vw;
    width: calc(30vw - 60px);
    cursor: zoom-in;
}

#item-page-carousel-display-big-alone {
    height: 30vw;
    width: 30vw;
    cursor: zoom-in;
}

#item-page-big-carousel-dir-buttons-area {
    position: absolute;
    width: var(--big-carousel-size);
    display: flex;
    justify-content: space-between;
    z-index: 3;
}

.item-page-big-dir-buttons {
    height: var(--big-carousel-size);
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.item-page-carousel-img {
    width: var(--big-carousel-size);
    height: var(--big-carousel-size);
    object-fit: cover;
}

#item-page-carousel-list-big {
    display: flex;
    transition: 0.8s ease-in-out
}

#item-page-big-image-area {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media (width <=768px) {
    #item-page {
        margin: 30px 0px;
        gap: 20px;
    }

    #item-page-top {
        flex-direction: column-reverse;
    }

    #big-carousel {
        top: 20vh;
    }

    #item-page-images-col {
        display: none;
    }

    #item-page-images-col-mobile {
        display: flex;
        flex-direction: column;
    }
}